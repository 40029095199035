import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { yooKassaJS } from "../../../base/configUrl";
import ModalContainerNew from "../../../Components/Modal/ModalContainerNew";
import { verifyPaymentAction } from "../../../base/asyncActions/Payment";

const PaymentModal = ({
  consultationId,
  yooKassaPaymentToken,
  consultationIsPaid,
}) => {
  // eslint-disable-next-line
  let [errorMessage, setErrorMessage] = useState(false);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [yooKassaScriptLoaded, setYooKassaScriptLoaded] = useState(false);
  const [yooKassaCheckout, setYooKassaCheckout] = useState(false);
  // eslint-disable-next-line
  const [secondsCounter, setSecondsCounter] = useState("");
  const [successPaid, setSuccessPaid] = useState(false);
  const navigate = useNavigate();
  const yooKassaPaymentFormId = "yookassa-payment-form";

  const addYooKassaScript = () => {
    const yooKassaElemtScriptId = "yookassa-widget-js";
    if (!document.getElementById(yooKassaElemtScriptId)) {
      const script = document.createElement("script");
      script.id = yooKassaElemtScriptId;
      script.src = yooKassaJS;
      script.addEventListener("load", () => {
        setYooKassaScriptLoaded(true);
      });
      document.getElementsByTagName("head")[0].appendChild(script);
    } else {
      setYooKassaScriptLoaded(true);
    }
  };

  // on load
  useEffect(() => {
    addYooKassaScript();
  }, []);

  useEffect(() => {
    if (consultationIsPaid) {
      setPaymentStatusModal(true);
      setSuccessPaid(true);
    }
  }, [consultationIsPaid]);

  const openPaymentService = () => {
    if (yooKassaPaymentToken && yooKassaScriptLoaded) {
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: yooKassaPaymentToken,
        customization: {
          modal: true,
        },
        error_callback: function (error) {
          setYooKassaCheckout(false);
          console.log(error);
        },
      });
      checkout.on("success", (event) => {
        if (checkout) {
          checkout.destroy();
          setYooKassaCheckout(false);
          setPaymentStatusModal(true);
        }
      });
      checkout.on("modal_close", () => {
        setYooKassaCheckout(false);
      });
      checkout.render(yooKassaPaymentFormId);
      setYooKassaCheckout(checkout);
    }
  };

  useEffect(() => {
    openPaymentService();
    // eslint-disable-next-line
  }, [yooKassaScriptLoaded, yooKassaPaymentToken]);

  useEffect(() => {
    // TODO, SHIT HISTORY BUTTON BLOCK
    // NEED MAKE NORMAL
    const disableBackButton = (event) => {
      if (yooKassaCheckout) {
        event.preventDefault();
        yooKassaCheckout.destroy();
        setYooKassaCheckout(false);
      }
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", disableBackButton);

    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, [yooKassaCheckout, navigate]);

  // eslint-disable-next-line
  const listenerPaymentModal = () => {
    console.log("message modal OPEN");
    openPaymentService();
  };

  useEffect(() => {
    window.addEventListener("openPaymentModal", listenerPaymentModal);
    return () => {
      window.removeEventListener("openPaymentModal", listenerPaymentModal);
    };
  }, [listenerPaymentModal]);

  useEffect(() => {
    let interval = false;
    if (consultationId && paymentStatusModal) {
      interval = setInterval(() => {
        verifyPaymentAction(
          consultationId,
          (status_code) => {
            // success
            if (status_code === "succeeded") {
              setSuccessPaid(true);
              clearInterval(interval);
            }
          },
          (error) => {
            // error
            console.log("error payment query", error);
          }
        );
      }, 3000);
      setSecondsCounter(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line
  }, [paymentStatusModal]);

  const onClosePaidModal = () => {
    if (successPaid) {
      // TODO make somthing normal to update data on same page
      window.location.href = "/my-consultation";
    }
  };

  return (
    <>
      <div id={yooKassaPaymentFormId}></div>
      <ModalContainerNew
        openModal={paymentStatusModal}
        setOpenModal={(status) => {
          setPaymentStatusModal(status);
        }}
        classPost="smallModal"
        onClose={onClosePaidModal}
      >
        <div style={{ maxHeight: "40vh", textAlign: "center" }}>
          {!successPaid ? (
            <>
              <h2 style={{ marginBottom: "20px" }}>Проверяем оплату</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ margin: "auto", background: "none" }}
                width="200"
                height="200"
                display="block"
                preserveAspectRatio="xMidYMid"
                viewBox="0 0 100 100"
              >
                <path fill="#407bff" d="M10 50a40 40 0 0080 0 40 42 0 01-80 0">
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    type="rotate"
                    values="0 50 51;360 50 51"
                  ></animateTransform>
                </path>
              </svg>
            </>
          ) : (
            <>
              <h2 style={{ marginTop: "10px", marginBottom: "20px" }}>
                Консультация успешно оплачена
              </h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 30 30"
              >
                <path
                  fill="#09a109"
                  d="M15 1.5C7.557 1.5 1.5 7.557 1.5 15S7.557 28.5 15 28.5 28.5 22.443 28.5 15 22.443 1.5 15 1.5zm0 25.8C8.217 27.3 2.7 21.783 2.7 15S8.217 2.7 15 2.7 27.3 8.217 27.3 15 21.783 27.3 15 27.3zm6.723-16.923a.601.601 0 010 .849l-8.4 8.4a.598.598 0 01-.846-.003l-4.2-4.2a.601.601 0 01.849-.849l3.774 3.777 7.977-7.977a.595.595 0 01.846.003z"
                ></path>
              </svg>
            </>
          )}
        </div>
      </ModalContainerNew>
    </>
  );
};
export default PaymentModal;
