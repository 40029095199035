import React from "react";
import s from "./Footer.module.css";
import { ReactComponent as Doctor } from "../../../img/Doctor_icon.svg";
import { ReactComponent as Note } from "../../../img/note_icon.svg";
import { ReactComponent as Home } from "../../../img/Home_icon.svg";
import { ReactComponent as Prof } from "../../../img/Profile_icon.svg";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import ChatModal from "../../../Components/Modal/Chat_Modal/ChatModal";
const Footer = () => {
  let config = useSelector((state) => state.config.config);
  const info = useSelector((state) => state.main.shortName);
  let Text = useSelector((state) => state.config.header_text);
  const availableScreenWidth = window.screen.availWidth;
  var url = document.location.pathname;
  const is_docs =
    url === "/docs/policy" || url === "/docs/agreement" || url === "/docs/deal"
      ? true
      : false;
  return availableScreenWidth <= 480 && Text === "Консультация" ? (
    ""
  ) : info?.data?.short_name ? (
    <section className={s.footer_container + " BackgroundBlue"}>
      <footer className={s.footer_full}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
          className="Container"
        >
          <div className={s.footer_img}>
            <img alt="" src={config.config.logo_footer} />
          </div>
          <div className={s.footer_text + " white_config"}>
            <p>{config.config.copyright.text}</p>
            <p>
              <a
                className={s.footer_link}
                href="https://vsedoctora.online/"
                target="_blank"
                rel="noreferrer"
              >
                © Сервис предоставлен ВсеДоктора™ 2022-2023
              </a>
            </p>
          </div>
        </div>
      </footer>
      {!is_docs ? (
        <div className={s.footer_Mobile}>
          <ul>
            <NavLink
              to={"main"}
              className={({ isActive }) =>
                isActive ? `${s.navLink} ${s.headerActiveLink}` : s.navLink
              }
            >
              <li>
                <Home
                  stroke={
                    Text === "Главная"
                      ? config.config.colors.color10
                      : config.config.colors.color4
                  }
                />
                <p
                  className={Text === "Главная" ? "blue_config" : "gray_config"}
                >
                  Главная
                </p>
              </li>
            </NavLink>
            <NavLink
              to={"my-consultation"}
              className={({ isActive }) =>
                isActive ? `${s.navLink} ${s.headerActiveLink}` : s.navLink
              }
            >
              <li>
                <Note
                  fill={
                    Text === "Мои записи"
                      ? config.config.colors.color10
                      : config.config.colors.color4
                  }
                />
                <p
                  className={
                    Text === "Мои записи" ? "blue_config" : "gray_config"
                  }
                >
                  Мои записи
                </p>
              </li>
            </NavLink>
            {config?.module?.chat?.is_active ? (
              <li className={s.navLink}>
                <ChatModal />
              </li>
            ) : null}
            <NavLink
              to={"my-doctor"}
              className={({ isActive }) =>
                isActive ? `${s.navLink} ${s.headerActiveLink}` : s.navLink
              }
            >
              <li>
                <Doctor
                  stroke={
                    Text === "Мои врачи"
                      ? config.config.colors.color10
                      : config.config.colors.color4
                  }
                />
                <p
                  className={
                    Text === "Мои врачи" ? "blue_config" : "gray_config"
                  }
                >
                  Мои врачи
                </p>
              </li>
            </NavLink>
            <NavLink
              to={"profile/view"}
              className={({ isActive }) =>
                isActive ? `${s.navLink} ${s.headerActiveLink}` : s.navLink
              }
            >
              <li>
                <div style={{ position: "relative" }}>
                  <Prof
                    fill={
                      Text === "Профиль"
                        ? config.config.colors.color10
                        : config.config.colors.color4
                    }
                  />
                  {info?.data?.has_notifications ? (
                    <div
                      style={{
                        position: "absolute",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                        top: 0,
                        right: 0,
                      }}
                    ></div>
                  ) : null}
                </div>
                <p
                  className={Text === "Профиль" ? "blue_config" : "gray_config"}
                >
                  Мой кабинет
                </p>
              </li>
            </NavLink>
          </ul>
        </div>
      ) : (
        ""
      )}
    </section>
  ) : (
    ""
  );
};
export default Footer;
