import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "./SliderStyle.css";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getSymptoms } from "../../../base/asyncActions/getMainPageInfo";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const SliderArrow = () => {
  const availableScreenWidth = window.screen.availWidth;
  let slide = 5.3;
  if (availableScreenWidth <= 768 && availableScreenWidth > 420) {
    slide = 3.6;
  } else if (availableScreenWidth <= 420) {
    slide = 2.8;
  }
  const [loadling, setLoading] = useState(true);
  const [Symptoms, setSymptoms] = useState([]),
    dispatch = useDispatch(),
    SymptomInfo = async () => {
      const response = await dispatch(getSymptoms());
      setLoading(false);
      if (response && response.status) {
        setSymptoms(response.data.items);
      }
    };
  useEffect(() => {
    SymptomInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let sceletonMap = [];
  for (var i = 0; i < 4; i++) {
    sceletonMap.push(i);
  }

  return (loadling && Symptoms.length <= 0) || Symptoms.length > 0 ? (
    <section className="SliderElement" style={{ marginBottom: "80px" }}>
      <h1 className="CardSwiperTitle title_config">Симптомы</h1>
      <div>
        <Swiper
          slidesPerView={slide}
          spaceBetween={10}
          slidesPerGroup={1}
          initialSlide={4}
          loop={true}
          centeredSlides={true}
          navigation={slide <= 4 ? false : true}
          modules={[Navigation]}
          className="mySwiper"
        >
          {loadling
            ? sceletonMap.map((sceletionId) => {
                return (
                  <SwiperSlide key={sceletionId}>
                    <Skeleton
                      className="swiperCard"
                      style={{ border: "none" }}
                    />
                  </SwiperSlide>
                );
              })
            : Symptoms.map((item) => (
                <SwiperSlide key={item.symptom_id}>
                  <Link
                    to={"/doctors/" + item.branch + "/" + item.specialization}
                    className="swiperCard"
                  >
                    <img alt="" className="swiperCardImg" src={item.image} />
                    <p className="switepCardText black_config">{item.title}</p>
                  </Link>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </section>
  ) : null;
};

export default SliderArrow;
