import React from "react";
import s from "../../Pages/Views/Consultation/Consultation.module.css";
import { useSelector } from "react-redux/es/exports";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import ModalContainer from "../Modal/ModalContainer";
import {
  axiosConsultationDelete,
  axiosConsultation,
} from "../../base/asyncActions/getConsultation";
import { axiosConsultationPuy } from "../../base/asyncActions/getConsultation";
import Button from "../Button/Button";
import Skeleton from "react-loading-skeleton";
import PaymentModal from "../../Pages/Views/Payment/PaymentModal";
const ConsultationNext = ({ existUpcomming }) => {
  let dispatch = useDispatch();
  let [consultationPaymentToken, setConsultationPaymentToken] = useState(false);
  let [consultationIsPaid, setConsultationIsPaid] = useState(false);
  let [consultationPaymentId, setConsultationPaymentId] = useState(false);
  let [consultationPaymentActive, setConsultationPaymentActive] =
    useState(false);
  let [updateTime, setUpdateTime] = useState(false);
  let [statusDoc, setStatus] = useState(false);

  const config = useSelector((state) => state.config.config);

  useEffect(() => {
    async function fetchMyAPI() {
      let statusDoctor = await dispatch(axiosConsultation());
      setStatus(statusDoctor.status);
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClickPay = async (id) => {
    if (!consultationPaymentActive) {
      setConsultationPaymentId(id);
      setConsultationPaymentActive(true);
      const response = await dispatch(axiosConsultationPuy(id));
      setConsultationPaymentActive(false);
      setConsultationIsPaid(response.is_paid);
      const token =
        typeof response.token !== "undefined" ? response.token : false;
      setConsultationPaymentToken(token);
      setUpdateTime(Date.now());
    }
  };
  let consultation = useSelector((state) => state.consultation.consultation);
  let sceletonMap = [];
  for (var i = 0; i < 2; i++) {
    sceletonMap.push(i);
  }

  return (
    <div>
      {consultationPaymentId ? (
        <PaymentModal
          updateTime={updateTime}
          consultationId={consultationPaymentId}
          yooKassaPaymentToken={consultationPaymentToken}
          consultationIsPaid={consultationIsPaid}
        />
      ) : null}
      {statusDoc
        ? consultation.length > 0
          ? consultation.map((el) => (
              <div
                className={s.Doctor_full + " black_config"}
                key={el.consultation_id}
              >
                {el.can_cancel ? (
                  <ModalContainer
                    typeModalCont="CancelRecord"
                    consultation_id={el.consultation_id}
                    type_modal="cons"
                    text={"Вы действительно хотите отменить запись?"}
                    func={axiosConsultationDelete}
                  />
                ) : (
                  ""
                )}
                <div className={s.Doctor_full1}>
                  <div className={s.Doctor}>
                    <div className={s.Doctor_infos}>
                      <div className={s.Doctor_avatar}>
                        <div className={s.Doctor_avatar_img}>
                          <img alt="" src={el.doctor.photo} />
                          {el.doctor.is_online && (
                            <div
                              className={s.DoctorOnline + " green_config"}
                            ></div>
                          )}
                        </div>
                        {/* <div className={s.Doctor_avatar_info + " " + s.black}>
                        <Stars num={el.doctor.rate} />
                      </div> */}
                      </div>
                    </div>
                    <div className={s.Doctor_info + " " + s.black}>
                      <p
                        className={"gray_config"}
                        style={{ color: config?.config.colors.color4 }}
                      >
                        {el.doctor.specialization.join(" • ")}
                      </p>
                      <h2 className={s.Font_size24}>
                        {el.doctor.lastname +
                          " " +
                          el.doctor.firstname +
                          " " +
                          el.doctor.secondname}
                      </h2>
                      <p className={s.Staj}>{el.doctor.regalia.join(" • ")}</p>
                      <div className={s.Doctor_buy}>
                        <p
                          className={"gray_config"}
                          style={{ color: config?.config.colors.color4 }}
                        >
                          Стоимость консультации:
                        </p>
                        <p className={s.buy}>{el.price} ₽</p>
                      </div>
                      <div className={s.ButtonMobile}>
                        <p
                          className={"gray_config"}
                          style={{ color: config?.config.colors.color4 }}
                        >
                          Консультация состоится:
                        </p>
                        <p className={s.buy}>
                          {new Date(el.datetime).toLocaleString("ru", {
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={s.Consultation_info}>
                    <div className={s.Consultation_info_text}>
                      <p
                        className={"gray_config"}
                        style={{ color: config?.config.colors.color4 }}
                      >
                        Консультация состоится:
                      </p>
                      <p className={s.buy}>
                        {new Date(el.datetime).toLocaleString("ru", {
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </p>
                    </div>
                    {!el.is_paid ? (
                      <div onClick={(e) => onClickPay(el.consultation_id)}>
                        <Button
                          className={s.Injoy1 + " " + s.Font_size14}
                          type={"submit"}
                          class={"btn orange"}
                          text={"Оплатить"}
                        />
                      </div>
                    ) : el.can_reschedule ? (
                      <ModalContainer
                        usId={el.doctor.doctor_id}
                        consultation_id={el.consultation_id}
                        typeModalCont="ModalCalendar"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={s.ButtonMobile}>
                  {!el.is_paid ? (
                    <div onClick={(e) => onClickPay(el.consultation_id)}>
                      <Button
                        className={s.Injoy1 + " " + s.Font_size14}
                        type={"submit"}
                        class={"btn orange"}
                        text={"Оплатить"}
                      />
                    </div>
                  ) : el.can_reschedule ? (
                    <ModalContainer
                      typeModalCont="ModalCalendar"
                      usId={el.doctor.doctor_id}
                      consultation_id={el.consultation_id}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))
          : !existUpcomming
          ? "Нет предстоящих консультаций"
          : ""
        : sceletonMap.map((sceletionId) => {
            return (
              <div
                className={s.Doctor_full + " black_config"}
                key={sceletionId}
              >
                <div className={s.Doctor_full1}>
                  <div className={s.Doctor}>
                    <div className={s.Doctor_infos}>
                      <div className={s.Doctor_avatar}>
                        <div className={s.Doctor_avatar_img}>
                          <Skeleton circle="1" style={{ height: "100%" }} />
                        </div>
                      </div>
                    </div>
                    <div className={s.Doctor_info + " " + s.black}>
                      <p
                        className={"gray_config"}
                        style={{ color: config?.config.colors.color4 }}
                      >
                        <Skeleton style={{ width: "70%" }} />
                      </p>
                      <h2 className={s.Font_size24}>
                        <Skeleton />
                      </h2>
                      <p className={s.Staj}>
                        <Skeleton style={{ width: "60%" }} />
                      </p>
                      <div className={s.Doctor_buy}>
                        <p
                          className={"gray_config"}
                          style={{ color: config?.config.colors.color4 }}
                        >
                          <Skeleton style={{ width: "40%" }} />
                        </p>
                        <p className={s.buy}>
                          <Skeleton style={{ width: "30%" }} />
                        </p>
                      </div>
                      <div className={s.ButtonMobile}>
                        <p
                          className={"gray_config"}
                          style={{ color: config?.config.colors.color4 }}
                        >
                          <Skeleton style={{ width: "100%" }} />
                        </p>
                        <p className={s.buy}>
                          <Skeleton style={{ width: "90%" }} />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={s.Consultation_info}>
                    <div className={s.Consultation_info_text}>
                      <p
                        className={"gray_config"}
                        style={{ color: config?.config.colors.color4 }}
                      >
                        <Skeleton style={{ width: "100%" }} />
                      </p>
                      <p className={s.buy}>
                        <Skeleton style={{ width: "90%" }} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
};
export default ConsultationNext;
