import React from "react";
import s from "../Chat_Modal/ChatModal.module.css";
import { useEffect, useState } from "react";
import chat from "../../../img/chat.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import ChatComponent from '../../../Pages/Views/Video/VideoChat/components/chat/ChatComponent';
import ChatSendComponent from "../../../Pages/Views/Video/VideoChat/components/chat/ChatSendComponent";

const ModalUnityChat = (props) => {
  let [showWindow, setWindow] = useState(false);

  useEffect(() => {
    if (!showWindow) {
      document.body.style.overflow = "auto";
    }
    if (showWindow) {
      document.body.style.overflow = "hidden";
    }
  }, [showWindow]);



  function scrollToBottom() {
    // TODO add event on message added to chat for scroll to bottom
    const scroll = document.querySelector('.mobileMessageContainer div[data-rsbs-scroll]');
    setTimeout(() => {
      try {
        if(scroll) {
          scroll.scrollTop = scroll.scrollHeight;
        }
      } catch (err) {}
    }, 200);
  }

  return (
    <div className={s.ChatUtility}>
      <li onClick={(e) => setWindow(true)}>
        <div className={s.icon_back}>
          <img alt="" src={chat} />
        </div>
        <p>Чат</p>
      </li>
      {/* {showWindow ? ( */}
        <BottomSheet 
          open={showWindow} 
          onDismiss={() => setWindow(false)}
          className="mobileMessageContainer"
          snapPoints={({ maxHeight }) => [
            maxHeight - maxHeight / 5,
            maxHeight * 0.6,
          ]}
          footer={
            <ChatSendComponent user={props.localUser} consultation={props.consultation}/>
          }
        >
          <div className={s.Chat_message}>
            <ChatComponent mobile={true} user={props.localUser} customScrollBottom={scrollToBottom} consultation={props.consultation}/>
          </div>
        </BottomSheet>
      {/* ) : (
        ""
      )} */}
    </div>
  );
};
export default ModalUnityChat;
