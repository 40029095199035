import React from "react";
import s from "../My_Profile.module.css";
import pen from "../../../../img/pen.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { getConfigHeaderAction } from "../../../../base/Reducers/configReducer";
import { axiosProfile } from "../../../../base/asyncActions/Profile";
import Skeleton from "react-loading-skeleton";
const Local_Data = () => {
  let dispatch = useDispatch();
  const logout = () => {
    localStorage.clear();
  };
  let [dateParts, setdateParts] = useState(1);
  const profile = useSelector((state) => state.profile);
  const info = useSelector((state) => state.main.shortName);
  useEffect(() => {
    dispatch(axiosProfile());
    dispatch(getConfigHeaderAction("Профиль"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let datePartsNew = profile.birthday.split("-");
    setdateParts(`${datePartsNew[2]}-${datePartsNew[1]}-${datePartsNew[0]}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  let phone = "";
  if (profile.phone) {
    let phoneParts = ("" + profile.phone).match(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/
    );
    phone = `+ ${phoneParts[1]} (${phoneParts[2]}) ${phoneParts[3]}-${phoneParts[4]}-${phoneParts[5]}`;
  }
  return (
    <div className={s.My_content}>
      <div className={s.Left_Position}>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${s.navLink} blue_config`
                  : s.navLink + " gray_config"
              }
              to="../view"
            >
              <p>Личные данные</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${s.navLink} blue_config`
                  : s.navLink + " gray_config"
              }
              to="../notifications"
            >
              <div style={{ position: "relative" }}>
                <p>Уведомления</p>
                {info?.data?.has_notifications ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: "red",
                      top: 0,
                      right: "-10px",
                    }}
                  ></div>
                ) : null}
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${s.navLink} blue_config`
                  : s.navLink + " gray_config"
              }
              to="../balance"
            >
              <p>Баланс</p>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${s.navLink} blue_config`
                  : s.navLink + " gray_config"
              }
              to="../medcard"
            >
              <p>Медицинская карта</p>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${s.navLink} blue_config`
                  : s.navLink + " gray_config"
              }
              to="../research"
            >
              <p>Результаты исследований</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${s.navLink}` : `${s.navLink + " " + s.close}`
              }
              onClick={() => logout()}
              to={"../../"}
            >
              Выйти
            </NavLink>
          </li>
        </ul>
        <div className={s.links + " blue_config"}>
          <Link to="/docs/agreement" className="blue_config">
            <p>Персональные данные</p>
          </Link>
          <Link to="/docs/policy" className="blue_config">
            <p>Политика конфиденциальности</p>
          </Link>
          <Link to="/docs/deal" className="blue_config">
            <p>Договор на оказание услуг</p>
          </Link>
        </div>
      </div>
      <div className={s.My_content_title + " title_config"}>
        <h1>Личные данные</h1>
      </div>
      {profile.lastname ? (
        <div className={s.My_content_container + " black_config"}>
          <div className={s.My_content_top}>
            <div className={s.My_content_top_image}>
              <img alt="" src={profile.photo} />
            </div>
            <div className={s.My_content_top_content}>
              <div className={s.My_content_top_title}>
                <h1>
                  {profile.lastname +
                    " " +
                    profile.firstname +
                    " " +
                    profile.secondname}
                </h1>
                <Link to={"../edit"}>
                  <img alt="" src={pen} />
                </Link>
              </div>
              <p>{phone}</p>
            </div>
          </div>
          <div className={s.My_content_bottom}>
            <span>
              <p>Дата рождения: {dateParts}</p>
            </span>
            <span>
              <p>Электронная почта: {profile.email}</p>
            </span>
            <span>
              <p>Пол: {profile.gender === 1 ? "Женский" : "Мужской"}</p>
            </span>
            <span>
              <p>Баланс: {profile.balance}₽</p>
            </span>
          </div>
        </div>
      ) : (
        <div className={s.My_content_top}>
          <div className={s.My_content_top_image}>
            <Skeleton circle="1" style={{ height: "100%" }} />
          </div>
          <div className={s.My_content_top_content}>
            <div className={s.My_content_top_title}>
              <h1>
                <Skeleton style={{ width: "70%" }} />
              </h1>
              <Link to={"../edit"}>
                <img alt="" src={pen} />
              </Link>
            </div>
            <p>
              <Skeleton style={{ width: "70%" }} />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default Local_Data;
