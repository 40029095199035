import axios from "axios";
import { defaultUrl } from "../configUrl";
import {
  paymentInfoAction,
  paymentDateTimeAction,
} from "../Reducers/paymentReducer";
export const getPaymentInfo = (
  id,
  slot,
  DateStr,
  use_balance = false,
  promocode = ""
) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await axios.post(`${defaultUrl}consultation/checkout`, {
        doctor_id: id,
        slot_id: slot,
        promocode: promocode,
        use_balance: use_balance,
      });
      dispatch(paymentInfoAction(response.data.data, slot));
      if (response.data.data.consultation_datetime)
        dispatch(
          paymentDateTimeAction(response.data.data.consultation_datetime)
        );
      return response.data;
    }
  };
};
export const payConsultationAction = (data, successCallback, errorCallback) => {
  const token = localStorage.getItem("token");
  if (token) {
    axios
      .post(`${defaultUrl}consultation/create`, data)
      .then((response) => {
        if (response.data.status && response.data.data.token) {
          successCallback({
            consultation_id: response.data.data.consultation_id,
            token: response.data.data.token,
          });
        } else if (response.data.status && response.data.data.is_paid) {
          successCallback({
            consultation_id: response.data.data.consultation_id,
            is_paid: response.data.data.is_paid,
          });
        } else {
          errorCallback(response.data?.message ?? "Ошибка оплаты");
        }
      })
      .catch((error) => {
        errorCallback("Ошибка оплаты");
      });
  }
};
export const verifyPaymentAction = (
  consultationId,
  successCallback,
  errorCallback
) => {
  const token = localStorage.getItem("token");
  if (token) {
    axios
      .get(`${defaultUrl}consultation/${consultationId}/pay_status`)
      .then((response) => {
        if (typeof response.data.status_code !== "undefined") {
          successCallback(response.data.status_code);
        } else {
          errorCallback(response.data);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  }
};
