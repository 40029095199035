import React, { useCallback } from "react";
import s from "./Payment.module.css";
import { useState, useEffect } from "react";
import {
  getPaymentInfo,
  payConsultationAction,
} from "../../../base/asyncActions/Payment";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
import { getConfigHeaderAction } from "../../../base/Reducers/configReducer";
import FormErrors from "../../../Components/FormError/FormError";
import { Link, Navigate } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import Chat from "../../../Components/Chat/Chat";
import Button from "../../../Components/Button/Button";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import PaymentModal from "./PaymentModal";

const Payment = () => {
  const dispatch = useDispatch();
  let payment = useSelector((state) => state.payment);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getConfigHeaderAction("Оплата"));
    // let title = config?.config.title ? config?.config.title : "Telemed";
    // document.title = 'Запись на приём: ' + payment.firstname + ' ' + payment.lastname + ' ' + payment.secondname + ' - ' + title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);
  let [errorMessage, setErrorMessage] = useState(false);
  let params = useParams();
  const [promocode, setPromocode] = useState("");
  // const [check, setcheck] = useState(false);
  const [consultationId, setConsultationId] = useState(false);
  const [consultationIsPaid, setConsultationIsPaid] = useState(false);
  const [yooKassaPaymentToken, setYooKassaPaymentToken] = useState(false);
  const [paymentRequested, setPaymentRequested] = useState(false);

  const payConsultation = () => {
    if (!paymentRequested && (!consultationId || !yooKassaPaymentToken)) {
      setPaymentRequested(true);
      payConsultationAction(
        {
          doctor_id: params.id,
          slot_id: payment.slot_id,
          promocode,
        },
        (data) => {
          setConsultationId(data.consultation_id);
          if (data.token) {
            setYooKassaPaymentToken(data.token);
          } else if (data.is_paid) {
            setConsultationIsPaid(true);
          }
          setPaymentRequested(false);
        },
        (message) => {
          setPaymentRequested(false);
          setErrorMessage(message);
        }
      );
    } else if (consultationId && yooKassaPaymentToken) {
      eventOpenPaymentModal();
    }
  };

  const eventOpenPaymentModal = () => {
    const openPaymentModal = new CustomEvent("openPaymentModal", {
      open: true,
    });
    window.dispatchEvent(openPaymentModal);
  };

  const updateCheckoutPromo = useCallback(() => {
    dispatch(
      getPaymentInfo(params.id, payment.slot_id, false, false, promocode)
    );
  }, [dispatch, params.id, payment.slot_id, promocode]);

  useEffect(() => {
    updateCheckoutPromo();
  }, [updateCheckoutPromo]);

  return payment.firstname ? (
    <div className={s.Container + " Container"}>
      <PaymentModal
        consultationId={consultationId}
        yooKassaPaymentToken={yooKassaPaymentToken}
        consultationIsPaid={consultationIsPaid}
      />
      <div className={s.Payment + " black_config"}>
        <div className={s.Payment_title + " title_config"}>
          <h1 className={s.Font_size40}>Запись на приём</h1>
        </div>
        <div className={s.Doctor}>
          <div className={s.Doctor_infos}>
            <div className={s.Doctor_avatar}>
              <div className={s.Doctor_avatar_img}>
                <img alt="" src={payment.photo} />
              </div>
              {/* <div className={s.Doctor_avatar_info + " " + s.black}> */}
              {/* <Stars num={payment.rate} /> */}
              {/* TODO PRERELEASE */}
              {/* <p className={s.Font_size14}>
                  {payment.recomends} пациентов рекомендуют врача
                </p>
                <Link
                  to={"../doctor/" + params.id + "/reviews"}
                  style={{ color: config?.config.colors.color10 }}
                >
                  <p className={s.Font_size14 + " blue_config"}>
                    {payment.reviews} отзывов
                  </p>
                </Link> */}
              {/* </div> */}
            </div>
            <div className={s.Doctor_info + " " + s.black}>
              <p className={s.gray + " " + s.Font_size14 + " gray_config"}>
                {payment.specialization.join(" • ")}
              </p>
              <h2 className={s.Font_size24}>
                {payment.lastname +
                  " " +
                  payment.firstname +
                  " " +
                  payment.secondname}
              </h2>
              <p className={s.Staj + " " + s.Font_size14}>
                {payment.regalia.join(" • ")}
              </p>
              <div className={s.Doctor_buy}>
                <p className={s.gray + " " + s.Font_size14 + " gray_config"}>
                  Стоимость консультации:
                </p>
                <p className={s.buy + " " + s.Font_size24}>
                  {payment.checkout.price} ₽
                </p>
              </div>
              <div className={s.Doctor_buy}>
                <p className={s.gray + " " + s.Font_size14 + " gray_config"}>
                  Дата и время приёма:
                </p>
                <p className={s.buy + " " + s.Font_size24}>
                  {new Date(payment.consultation_datetime).toLocaleString(
                    "ru",
                    {
                      hour: "numeric",
                      minute: "numeric",
                      month: "long",
                      year: "numeric",
                      day: "numeric",
                    }
                  )}
                </p>
              </div>
              <ModalContainer
                promocode={promocode}
                use_balance={false}
                typeModalCont="ModalCalendar"
                type_of="1"
                doctor_id={params.id}
                usId={params.id}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={s.Summ}>
            <DebounceInput
              debounceTimeout={500}
              type="text"
              placeholder="Промо или реферальный код"
              name={"promocode"}
              onChange={async (e) => {
                setPromocode(e.target.value);
              }}
            />
          </div>
          <div className={s.Oplata + " black_config"}>
            {/* <p className={s.Font_size24}>Баланс: {payment.checkout.price} ₽</p>
            <div className={s.Balance}>
              <input
                type="checkbox"
                id="Register_checkbox"
                value={true}
                name={"use_balance"}
                className={s.custom_checkbox}
                onChange={handleChangeCheck}
              />
              <p className={s.Font_size14}>Оплатить с баланса</p>
            </div>
            {check ? (
              <span>
                <p className={s.Font_size16}>Списано с баланса: </p>
                <b className={s.Font_size16}>
                  {" "}
                  -{payment.checkout.used_balance} ₽
                </b>
              </span>
            ) : (
              ""
            )} */}
            {promocode ? (
              <span>
                <p className={s.Font_size16}>Скидка:&nbsp;</p>
                <b className={s.Font_size16}>
                  {" "}
                  {payment.checkout.used_promo > 0 ? "-" : ""}
                  {payment.checkout.used_promo} ₽
                </b>
              </span>
            ) : (
              ""
            )}
          </div>
          <div className={s.Total_sum + " black   _config"}>
            <span className={s.Font_size24}>
              <p>Всего: </p> <b>{payment.checkout.total} ₽</b>
            </span>
            {errorMessage ? <FormErrors error={errorMessage} /> : null}
            {payment.checkout.total > payment.checkout.used_promo ? (
              <FormErrors error="Сервис телемедицинских консультаций запущен в тестовом режиме. В данный момент получить консультацию возможно только бесплатно по промокоду." />
            ) : null}
            <Button
              onClick={payConsultation}
              class="btn blue"
              text="Оплатить"
              disabled={payment.checkout.total > payment.checkout.used_promo}
            />
            <p className={s.Font_size14}>
              Нажимая «Оплатить», я принимаю условия{" "}
              <Link to="/docs/deal" className="blue_config">
                договора на оказание услуг
              </Link>{" "}
              и даю повторное{" "}
              <Link to="/docs/agreement" className="blue_config">
                {" "}
                согласие на обработку персональных данных
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
      <Chat />
    </div>
  ) : (
    <Navigate to={`../doctor/${params.id}/info`} />
  );
};
export default Payment;
